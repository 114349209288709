.rerender {
    -webkit-animation: flip-out-hor-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: flip-out-hor-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes flip-out-hor-top {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        opacity: 0;
    }
}
@keyframes flip-out-hor-top {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        opacity: 0;
    }
}

.render {
    -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes flip-in-hor-bottom {
    0% {
        -webkit-transform: rotateX(80deg);
        transform: rotateX(80deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}
@keyframes flip-in-hor-bottom {
    0% {
        -webkit-transform: rotateX(80deg);
        transform: rotateX(80deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}
